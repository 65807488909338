import styled from '@emotion/styled';
import {colors} from '../../theme';
export const StepperContainer = styled('div', {target: 'tw-contents'})`
    .stepper-component {
        &.error > .incrementer {
            button,
            input {
                border-color: ${colors.red[500]};
            }
        }

        .incrementer {
            input {
                padding: 0;
            }

            button {
                border-color: ${colors.gray[200]};

                &:disabled {
                    opacity: 1;
                }

                &:disabled svg {
                    color: ${colors.gray[250]};
                    cursor: not-allowed;
                }
            }

            button:hover,
            button:active {
                background-color: white !important;
            }

            button:focus {
                box-shadow: none !important;
            }

            .input-group-prepend {
                height: 38px;
            }

            .input-group-text {
                width: 38px;
            }

            .svg-inline--fa {
                color: ${colors.gray[650]};
                cursor: pointer;
                font-size: 20px;
                vertical-align: text-top;
            }

            span > .svg-inline--fa {
                padding: 16px;
            }

            .validationError {
                border: 2px solid ${colors.red[500]};
            }

            .disabled {
                color: ${colors.gray[400]};
                cursor: not-allowed;
            }

            input {
                height: 38px;
                width: 38px;
                min-width: 38px;
                font-size: 1rem;
                flex: none;
            }
        }

        &.stepper-lg {
            .input-group-prepend,
            .input-group-append {
                height: 52px;

                button {
                    width: 52px;
                    height: 52px;
                }
            }

            .svg-inline--fa {
                color: ${colors.gray[650]};
                cursor: pointer;
                font-size: 26px;
            }

            button {
                border-color: ${colors.gray[200]};
                width: 52px;

                &:disabled {
                    opacity: 1;
                }

                &:disabled i {
                    color: ${colors.gray[250]};
                }
            }

            .input-group-text {
                width: 52px;
                height: 52px;
            }

            input {
                height: 52px !important;
                width: 52px !important;
            }
        }

        &.stepper-full {
            input {
                flex: 1 1 auto;
            }
        }

        @media only screen and (max-width: 767px) {
            .incrementer {
                input {
                    flex: 1 1 auto !important;
                    width: 100% !important;
                }

                .input-group-prepend,
                .input-group-append {
                    display: block;
                }
            }
            .stepper-full-sm {
                width: 100%;
                input {
                    flex: 1 1 auto !important;
                    width: 100% !important;
                }
            }
        }
    }
`;
